import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import MultiSelectBanner from "@src/deprecatedDesignSystem/components/MultiSelectBanner";
import { TableContentRow } from "@src/deprecatedDesignSystem/components/table/TableContentRow";
import { TableHeaderRow } from "@src/deprecatedDesignSystem/components/table/TableHeaderRow";
import TableSkeletonState from "@src/deprecatedDesignSystem/components/table/TableSkeletonState";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { useScreenDimensions } from "@hooks/useScreenDimensions";
import { downloadCSV } from "@utils/csv";
import { debounce } from "lodash";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ViewportList } from "react-viewport-list";
import {
  ExportConfig,
  HeaderCellStyles,
  HeaderTooltipCopy,
  KeyType,
  MultiSelectConfig,
  RenderFns,
  ResponsivenessConfigs,
  SortConfig,
  TableItemCount,
} from "./Table";
import {
  LazyQueryResultTuple,
  QueryHookOptions,
  QueryResult,
} from "@apollo/client";
import { InputMaybe, PaginationInput } from "@src/types.generated";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import Spinner from "../Spinner";
import { Route } from "nextjs-routes";
import { isNotNullOrUndefined, isNullOrUndefined } from "@utils/typeguards";
import { useDebouncedQueryParam } from "@src/hooks/useDebouncedQueryParam";
import { useMediaQuery } from "@src/hooks/useMediaQuery";
import { Button } from "@src/ui/button";
import ArrowDownloadIcon from "@src/ui/icons/18px/arrow-download";

const LIMIT = 40;

export type RowHeight =
  | number
  | { wrap: true; minHeight: number; maxHeight: number };

type QueryHook = (
  baseOptions: QueryHookOptions<
    any, // eslint-disable-line @typescript-eslint/no-explicit-any
    {
      input: any; // eslint-disable-line @typescript-eslint/no-explicit-any
      pagination?: InputMaybe<PaginationInput> | undefined;
    }
  >,
) => QueryResult<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
type LazyQueryHook = (
  baseOptions: QueryHookOptions<
    any, // eslint-disable-line @typescript-eslint/no-explicit-any
    {
      input: any; // eslint-disable-line @typescript-eslint/no-explicit-any
      pagination?: InputMaybe<PaginationInput> | undefined;
    }
  >,
) => LazyQueryResultTuple<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

export interface TableProps<
  T,
  Column extends string,
  Hook extends QueryHook,
  LazyHook extends LazyQueryHook,
  ExportColumn extends string,
> {
  queryHook: Hook;
  lazyQueryHook: LazyHook;
  getItems: (data: ReturnType<Hook>["data"]) => T[];
  getTotalItemCount: (data: ReturnType<Hook>["data"]) => number;
  itemId: (item: T) => string | number;
  title?: string | React.ReactNode;
  allColumns: Column[];
  visibleColumns?: () => Column[];
  columnTitles?: Record<Column, string>;
  renderFns: RenderFns<T, Column>;
  responsiveness?: ResponsivenessConfigs<Column>;
  multiselect?: MultiSelectConfig<T>;
  rowStyles?: CSSProperties;
  rowHeight?: RowHeight;
  onRowClick?: (item: T) => void;
  rowClickLink?: (item: T) => Route | undefined | null;
  disableRowClickOnColumns?: Set<Column>;
  overflowMenu?: (item: T) => React.ReactNode;
  rightAction?: React.ReactNode;
  leftAction?: React.ReactNode;
  loading?: boolean;
  headerStickyPositioning?: number;
  noDataEmptyState?: React.ReactNode;
  fullEmptyState?: React.ReactNode;
  exportConfig?: ExportConfig<T, ExportColumn>;
  defaultSort?: SortConfig<Column>;
  disabledSorts?: Column[];
  filters?: NonNullable<Parameters<QueryHook>[0]["variables"]>["input"];
  input?: NonNullable<Parameters<QueryHook>[0]["variables"]>["input"];
  allItemsCountInput?: NonNullable<
    Parameters<QueryHook>[0]["variables"]
  >["input"];
  hideCount?: boolean;
  pollInterval?: number;
  activeColumn?: Column;
  headerCellStyles?: HeaderCellStyles<Column>;
  headerHeight?: RowHeight;
  enableSearch?: boolean;
  showSortDirection?: boolean;
  headerTooltipCopy?: HeaderTooltipCopy<Column>;
  multilineHeader?: boolean;
  tableStyles?: CSSProperties;
  headerBreakPoint?: number;
  selectedLibraryItemIdsFromBulkUpload?: string[];
  SubHeader?: React.ReactNode;
  onColumnHeaderClick?: (column: Column) => void;
}

export default function PaginatedTable<
  T,
  Column extends string,
  Hook extends QueryHook,
  LazyHook extends LazyQueryHook,
  ExportColumn extends string,
>({
  allColumns,
  lazyQueryHook,
  itemId,
  visibleColumns,
  columnTitles,
  title,
  renderFns,
  responsiveness,
  multiselect,
  rowStyles,
  rowHeight = 42,
  onRowClick,
  rowClickLink,
  overflowMenu,
  leftAction,
  rightAction,
  onColumnHeaderClick,
  headerStickyPositioning,
  disableRowClickOnColumns,
  noDataEmptyState,
  fullEmptyState,
  exportConfig,
  queryHook,
  input,
  allItemsCountInput,
  hideCount,
  getItems,
  getTotalItemCount,
  defaultSort,
  pollInterval,
  disabledSorts,
  activeColumn,
  headerCellStyles,
  enableSearch = true,
  showSortDirection,
  headerHeight,
  headerTooltipCopy,
  multilineHeader = false,
  tableStyles,
  headerBreakPoint,
  selectedLibraryItemIdsFromBulkUpload,
  SubHeader,
}: TableProps<T, Column, Hook, LazyHook, ExportColumn>): JSX.Element {
  const {
    value: search,
    setValue: setSearch,
    debouncedValue: debouncedSearch,
  } = useDebouncedQueryParam({
    queryParamName: "q",
    initialValue: "",
    delay: 300,
  });
  const [sort, setSort] = useQueryParam<SortConfig<Column> | undefined>(
    "sort",
    withDefault(JsonParam, defaultSort),
  );
  const ref = useRef<HTMLDivElement | null>(null);
  const _input = useMemo(() => {
    return {
      ...input,
      search:
        debouncedSearch.length > 0 ? { value: debouncedSearch } : undefined,
      sort: sort ? sort : undefined,
    };
  }, [debouncedSearch, input, sort]);
  const { data, loading, fetchMore } = queryHook({
    pollInterval,
    variables: {
      input: _input,
      pagination: {
        limit: LIMIT,
        offset: 0,
      },
    },
  });
  const { data: totalCountData, loading: totalCountLoading } = queryHook({
    skip: hideCount || isNullOrUndefined(allItemsCountInput),
    variables: {
      input: allItemsCountInput || {},
      pagination: {
        limit: 0,
      },
    },
  });
  const [fetchAll, { loading: fetchAllLoading }] = lazyQueryHook({
    variables: {
      input: _input,
    },
  });
  const isDesktop = useMediaQuery(`(min-width: ${headerBreakPoint || 1}px)`);
  const items = useMemo(() => {
    return getItems(data);
  }, [data, getItems]);
  const itemCount = useMemo(() => {
    return getTotalItemCount(data);
  }, [data, getTotalItemCount]);
  const [lastSelectedItemId, setLastSelectedItemId] = useState<KeyType>();
  const [allFilteredItemsSelected, setAllFilteredItemsSelected] =
    useState(false);
  const [selectedItemIds, setSelectedItemIds] = useState<Set<KeyType>>(
    new Set(),
  );
  useEffect(() => {
    setSelectedItemIds(new Set(selectedLibraryItemIdsFromBulkUpload));
  }, [selectedLibraryItemIdsFromBulkUpload]);

  const debouncedFetchMore = debounce(
    () =>
      fetchMore({
        variables: {
          input: _input,
          pagination: {
            offset: items.length,
            limit: LIMIT,
          },
        },
      }),
    500,
  );
  const toggleItemSelected = useCallback(
    (item: T, holdingShift: boolean) => {
      setLastSelectedItemId(itemId(item));
      setSelectedItemIds((oldState) => {
        const newState = new Set(oldState);
        if (newState.has(itemId(item))) {
          newState.delete(itemId(item));
          setAllFilteredItemsSelected(false);
        } else {
          newState.add(itemId(item));
        }
        return newState;
      });
      if (!holdingShift) {
        return;
      }

      const lastSelectedItemIndex = items.findIndex((x) => {
        return itemId(x) === lastSelectedItemId;
      });
      const toggledItemIndex = items.findIndex((x) => {
        return itemId(x) === itemId(item);
      });
      if (lastSelectedItemIndex === -1 || toggledItemIndex === -1) {
        return;
      }
      const min = Math.min(lastSelectedItemIndex, toggledItemIndex);
      const max = Math.max(lastSelectedItemIndex, toggledItemIndex);
      const newSelectedItemIds = new Set(selectedItemIds);
      items.slice(min, max + 1).forEach((x) => {
        if (selectedItemIds.has(itemId(item))) {
          newSelectedItemIds.delete(itemId(x));
        } else {
          newSelectedItemIds.add(itemId(x));
        }
      });
      setSelectedItemIds(newSelectedItemIds);
    },
    [itemId, items, lastSelectedItemId, selectedItemIds],
  );
  const toggleAllFilteredItemsSelected = useCallback(() => {
    setAllFilteredItemsSelected(!allFilteredItemsSelected);
    if (allFilteredItemsSelected) {
      setSelectedItemIds(new Set());
    } else {
      setSelectedItemIds(
        new Set(
          items
            .filter((x) => {
              return !(
                multiselect?.disableMultiSelectForItem &&
                multiselect.disableMultiSelectForItem(x)
              );
            })
            .map((x) => itemId(x)),
        ),
      );
    }
  }, [allFilteredItemsSelected, itemId, items, multiselect]);
  const deselectAllSelectedItems = useCallback(() => {
    setAllFilteredItemsSelected(false);
    setSelectedItemIds(new Set());
  }, [setSelectedItemIds]);
  const { width: screenWidth } = useScreenDimensions();
  const columnsToDisplay: Column[] = useMemo(
    () =>
      (visibleColumns?.() || allColumns).filter((x) => {
        const columnPageMinWidth =
          responsiveness?.[x]?.collapseAtScreenWidth || 0;
        return screenWidth > columnPageMinWidth;
      }),
    [visibleColumns, allColumns, responsiveness, screenWidth],
  );
  const showLoadingState = useMemo(() => loading && !data, [loading, data]);
  const showNoSearchResultsEmptyState = useMemo(() => {
    return (
      itemCount !== undefined &&
      itemCount > 0 &&
      items.length === 0 &&
      !showLoadingState
    );
  }, [items.length, showLoadingState, itemCount]);
  const selectedItems = useMemo(
    () => items.filter((x) => selectedItemIds.has(itemId(x))),
    [items, itemId, selectedItemIds],
  );
  const [exportLoading, setExportLoading] = useState(false);
  const hasMore = useMemo(() => {
    return itemCount !== undefined && items.length < itemCount;
  }, [itemCount, items.length]);
  const hasNoData = useMemo(
    () => !showLoadingState && itemCount !== undefined && itemCount === 0,
    [showLoadingState, itemCount],
  );
  const downloadCSVWrapper = useCallback(async () => {
    if (!exportConfig) {
      return;
    }
    setExportLoading(true);
    const { data } = await fetchAll();
    const rows = getItems(data);
    setExportLoading(false);
    if (!rows) {
      return;
    }
    const columns = exportConfig.exportColumns();
    downloadCSV(
      exportConfig.title,
      columns,
      rows.map((i) => {
        return columns.map((c) => exportConfig.exportFns[c](i));
      }),
    );
  }, [exportConfig, fetchAll, getItems]);

  const ExportButton = useMemo(() => {
    if (!exportConfig) {
      return null;
    }
    return (
      <Button
        variant="outline"
        disabled={exportLoading}
        onClick={downloadCSVWrapper}
      >
        <ArrowDownloadIcon className="text-muted-foreground" />
        Export
      </Button>
    );
  }, [exportConfig, downloadCSVWrapper, exportLoading]);

  if (hasNoData && fullEmptyState) {
    return (
      <AutoLayout alignSelf="stretch" flex={1}>
        {fullEmptyState}
      </AutoLayout>
    );
  }

  return (
    <AutoLayout
      id={"paginated-table-container"}
      direction={"vertical"}
      alignSelf="stretch"
    >
      {(rightAction || exportConfig || leftAction) && (
        <AutoLayout
          direction={isDesktop ? "horizontal" : "vertical"}
          alignSelf={"stretch"}
          spacingMode={"space-between"}
          alignmentVertical={"center"}
          spaceBetweenItems={isDesktop ? 0 : 8}
        >
          <AutoLayout alignmentVertical="center">
            {leftAction}
            {title && (
              <AutoLayout marginRight={8}>
                {typeof title === "string" ? (
                  <Text type={"H4"} fontWeight={"SemiBold"}>
                    {title}
                  </Text>
                ) : (
                  title
                )}
              </AutoLayout>
            )}
            {showLoadingState ? (
              <div />
            ) : !hideCount && isNotNullOrUndefined(allItemsCountInput) ? (
              <TableItemCount
                loading={totalCountLoading}
                count={itemCount}
                totalCount={getTotalItemCount(totalCountData)}
              />
            ) : null}
          </AutoLayout>
          <AutoLayout direction={"horizontal"} spaceBetweenItems={8}>
            {enableSearch && (
              <AutoLayout style={{ maxWidth: 200, width: 200 }}>
                <TextField
                  placeholder="Search..."
                  text={search || ""}
                  leftIcon="search"
                  onTextChange={setSearch}
                />
              </AutoLayout>
            )}
            {rightAction}
            {ExportButton}
          </AutoLayout>
        </AutoLayout>
      )}
      {SubHeader}
      <AutoLayout
        direction={"vertical"}
        style={{ alignSelf: "stretch", ...tableStyles }}
      >
        {columnTitles && (
          <TableHeaderRow
            columns={columnsToDisplay}
            columnTitles={columnTitles}
            responsiveness={responsiveness}
            multiselect={multiselect}
            selectedSort={sort}
            setSelectedSort={(val) => {
              setSort?.(val);
            }}
            onColumnHeaderClick={onColumnHeaderClick}
            allFilteredItemsSelected={allFilteredItemsSelected}
            toggleAllFilteredItemsSelected={toggleAllFilteredItemsSelected}
            includeOverflowMenuColumn={!!overflowMenu}
            stickyPositioning={headerStickyPositioning}
            isSkeletonState={showLoadingState}
            disabledSorts={disabledSorts}
            activeColumn={activeColumn}
            headerCellStyles={headerCellStyles}
            headerHeight={headerHeight}
            showSortDirection={showSortDirection}
            headerTooltipCopy={headerTooltipCopy}
            multiline={multilineHeader}
          />
        )}
        <div
          ref={ref}
          style={{
            alignSelf: "stretch",
            flexGrow: 1,
            flex: 1,
            height: itemCount === 0 ? 120 : "auto",
          }}
        >
          {!showLoadingState && (
            <ViewportList
              items={items}
              itemMinSize={
                typeof rowHeight === "number" ? rowHeight : undefined
              }
              onViewportIndexesChange={([, end]) => {
                if (hasMore && end >= items.length - 1) {
                  debouncedFetchMore();
                }
              }}
            >
              {(item) => {
                return (
                  <TableContentRow
                    key={itemId(item)}
                    item={item}
                    columns={columnsToDisplay}
                    renderFns={renderFns}
                    multiselect={multiselect}
                    responsiveness={responsiveness}
                    isSelected={selectedItemIds.has(itemId(item))}
                    toggleItemSelected={toggleItemSelected}
                    rowStyles={rowStyles}
                    rowHeight={rowHeight}
                    onRowClick={onRowClick}
                    rowClickLink={rowClickLink}
                    disableRowClickOnColumns={disableRowClickOnColumns}
                    overflowMenu={overflowMenu}
                    activeColumn={activeColumn}
                  />
                );
              }}
            </ViewportList>
          )}
        </div>
        {showNoSearchResultsEmptyState && (
          <AutoLayout
            alignSelf={"stretch"}
            paddingTop={80}
            alignmentHorizontal={"center"}
          >
            <Text
              type={"P2"}
              fontWeight={"Medium"}
              color={deprecatedTones.gray6}
            >
              No results
            </Text>
          </AutoLayout>
        )}
        {showLoadingState && (
          <TableSkeletonState
            allColumns={allColumns}
            columnsToDisplay={columnsToDisplay}
            overflowMenu={overflowMenu}
            multiselect={multiselect}
            responsiveness={responsiveness}
            rowHeight={rowHeight}
            rowStyles={rowStyles}
          />
        )}
        {hasNoData && (
          <AutoLayout
            alignSelf={"stretch"}
            paddingTop={80}
            alignmentHorizontal={"center"}
          >
            {!noDataEmptyState || typeof noDataEmptyState === "string" ? (
              <Text
                type={"P2"}
                fontWeight={"Medium"}
                color={deprecatedTones.gray6}
              >
                {noDataEmptyState || "Nothing to display"}
              </Text>
            ) : (
              noDataEmptyState
            )}
          </AutoLayout>
        )}
        {multiselect && (
          <MultiSelectBanner
            numSelected={selectedItemIds.size}
            onDeselectAll={deselectAllSelectedItems}
            actions={multiselect.actions(
              selectedItems,
              deselectAllSelectedItems,
            )}
            totalItemCount={itemCount}
            selectAllLoading={fetchAllLoading}
            selectAll={
              fetchAll
                ? () => {
                    fetchAll().then(({ data }) => {
                      if (data) {
                        const items = getItems(data);
                        setSelectedItemIds(new Set(items.map(itemId)));
                      }
                    });
                  }
                : undefined
            }
            hasDestroyPermission={multiselect.hasDestroyPermission}
            destroyLabel={multiselect.destroyLabel}
            onDestroy={
              multiselect.onDestroy
                ? () => {
                    if (multiselect.onDestroy) {
                      multiselect.onDestroy(
                        selectedItems,
                        deselectAllSelectedItems,
                      );
                    }
                  }
                : undefined
            }
          />
        )}
        <AutoLayout
          alignmentHorizontal="center"
          alignmentVertical="center"
          height={64}
          style={{
            width: "100%",
          }}
        >
          {hasMore && <Spinner color={deprecatedTones.blue9} />}
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
}
